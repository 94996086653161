import { Patient } from "library/common/types/patientTypes"
import { ContextQuery } from "library/common/types/userTypes"
import fetch from "../utilities/fetch"

export const requestGetAllPatients = (params: ContextQuery) =>
  fetch.get("/patient/", { params })

export const requestGetActivePatient = (
  patientUuid: string,
  params: ContextQuery
) => fetch.get(`/patient/${patientUuid}/`, { params })

export const overwritePatient = (patient: Patient) =>
  fetch.post("/patient/?overwrite=1", { ...patient })
export const requestExternalPatient = (
  externalPatientId: string,
  params: ContextQuery
) => fetch.get(`/external-patient/${externalPatientId}/`, { params })

export const requestLinkPatient = (
  externalPatientId: string,
  link: string | null,
  params: ContextQuery
) =>
  fetch.patch(`/external-patient/${externalPatientId}/`, { link }, { params })

export const requestPatientMetaData = (
  patientUuid: string,
  params: ContextQuery
) => fetch.get(`/patient/${patientUuid}/metadata/`, { params })

export const requestAddNewPatient = (patient: Patient) =>
  fetch.post("/patient/", { ...patient })
export const requestDeletePatient = (patientUuid: string) =>
  fetch.delete(`/patient/${patientUuid}/`)
