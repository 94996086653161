import { createAction } from "typesafe-actions"
import {
  DismissedNotification,
  SaveComplete,
  SavingData,
  SavingTypes,
} from "../types/savingTypes"
import { ResultStatus } from "../types/dataStructureTypes"

export const requestSendChanges = createAction(
  SavingTypes.REQUEST_SEND_CHANGES
)<SavingData>()
export const requestSendChangesComplete = createAction(
  SavingTypes.REQUEST_SEND_CHANGES_COMPLETE
)<SaveComplete>()
export const setSavingResultStatus = createAction(
  SavingTypes.SET_SAVING_RESULT_STATUS
)<ResultStatus>()
export const setDataIsChanged = createAction(
  SavingTypes.SET_DATA_IS_CHANGED
)<boolean>()
export const requestSendEvent = createAction(
  SavingTypes.REQUEST_SEND_EVENT
)<string>()
export const setNotificationDismissed = createAction(
  SavingTypes.SET_NOTIFICATION_DISMISSED
)<DismissedNotification>()
