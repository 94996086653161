import { RootState } from "core/store/configureStore"
import { createSelector } from "reselect"
import { getBonelossPro } from "./image"

export const getFiltersState = ({ filters }: RootState) => filters

export const getShowCaries = ({ filters }: RootState) =>
  filters.showCaries && filters.isEnabled
export const getShowApical = ({ filters }: RootState) =>
  filters.showApical && filters.isEnabled
export const getShowOther = ({ filters }: RootState) =>
  filters.showOther && filters.isEnabled
export const getIsEnabled = ({ filters }: RootState) => filters.isEnabled

export const getActiveNames = createSelector(
  [getShowCaries, getShowApical, getShowOther, getIsEnabled],
  (showCaries, showApical, showOther, isEnabled) => {
    return {
      restorations: showOther && isEnabled,
      apical: showApical && isEnabled,
      caries: showCaries && isEnabled,
      calculus: showOther && isEnabled,
      nervus: showOther && isEnabled,
      impacted: showOther && isEnabled,
      annotate: showOther && isEnabled,
    }
  }
)

export const getShowNerveCanal = createSelector(
  [getBonelossPro, getFiltersState, getIsEnabled],
  (bonelossPro, filtersState, isEnabled) =>
    bonelossPro || !isEnabled ? false : filtersState.showNerveCanal
)
