import { ActionType, getType } from "typesafe-actions"

import * as actions from "../actions/upload"
import { Detection } from "../types/dataStructureTypes"

import { Tooth } from "@dentalxrai/transform-landmark-to-svg"
import { ICurrentBatchUpload } from "../types/uploadTypes"
import { ActionWithData } from "../actions/upload"

export interface ReadOnlyFields {
  dateOfBirth: boolean
  imageDate: boolean
  patientID: boolean
  patientName: boolean
}

export interface ToothWithDetection {
  toothName: string
  apical: boolean
  bone: boolean
  bridges: boolean
  caries: boolean
  crowns: boolean
  roots: boolean
  fillings: boolean
  implants: boolean
  toothDelete: boolean
  comment: string
}

export type DataImage = Readonly<{
  apical: Detection[]
  caries: Detection[]
  restorations: Detection[]
  paro: Detection[]
  teeth: Tooth[]
}>

type UploadState = Readonly<{
  idImage: string
  showReport: boolean
  showButton: boolean
  showImmediately: boolean
  demoMode: boolean
  imageBase64: string
  pending: boolean
  dateOfBirth: string
  originalDateOfBirth: string
  imageDateCreate: string
  imageDate: string
  originalImageDate: string
  patientName: string
  originalPatientName: string
  preloader: boolean
  dataImage: any
  statusFilter: boolean
  newDataTooth: ToothWithDetection[]
  globalComment: string
  reportSent: boolean
  xRayType: string
  patientID: string
  originalPatientID: string
  orginalChanges: ToothWithDetection[]
  isFlipped: boolean
  originalIsFlippedStatus: boolean
  rotate: number
  imageFormatOrig: string
  isSavingChanges: boolean
  originalGlobalComment: string
  fileName: string
  imageSizeError: string
  reportProblemId: string
  shouldTakeScreenshot: boolean
  screenshot: string
  isPermissionError: boolean
  readOnlyFields: ReadOnlyFields
  data: any
  openDateMs: number
  highSensitivity: string | undefined
  highSensitivityAvailable: Set<string>
  currentBatchUpload: ICurrentBatchUpload
  uploadFiles: ActionWithData[]
}>

const initialState: UploadState = {
  idImage: "",
  pending: false,
  showReport: false,
  showButton: true,
  showImmediately: false,
  demoMode: false,
  preloader: false,
  imageBase64: "",
  dateOfBirth: "",
  originalDateOfBirth: "",
  imageDateCreate: "",
  imageDate: "",
  originalImageDate: "",
  patientName: "",
  originalPatientName: "",
  globalComment: "",
  dataImage: {},
  newDataTooth: [],
  statusFilter: true,
  reportSent: true,
  xRayType: "",
  patientID: "",
  originalPatientID: "",
  orginalChanges: [],
  isFlipped: false,
  originalIsFlippedStatus: false,
  rotate: 0,
  imageFormatOrig: "",
  isSavingChanges: false,
  originalGlobalComment: "",
  fileName: "",
  imageSizeError: "",
  reportProblemId: "",
  shouldTakeScreenshot: false,
  screenshot: "",
  isPermissionError: false,
  readOnlyFields: {
    dateOfBirth: false,
    imageDate: false,
    patientID: false,
    patientName: false,
  },
  data: null,
  openDateMs: 0,
  highSensitivity: undefined,
  highSensitivityAvailable: new Set<string>(),
  currentBatchUpload: {
    total: null,
    error: null,
    success: null,
  },
  uploadFiles: [],
}

type UploadActions = ActionType<typeof actions>

export default (state = initialState, action: UploadActions): UploadState => {
  switch (action.type) {
    case getType(actions.setShouldTakeScreenshot):
      return {
        ...state,
        shouldTakeScreenshot: action.payload,
      }

    case getType(actions.setScreenshot):
      return {
        ...state,
        screenshot: action.payload,
      }
    case getType(actions.setReportProblemId):
      return { ...state, reportProblemId: action.payload }
    case getType(actions.uploadActionPreloaderImage):
      return {
        ...state,
        preloader: true,
      }
    case getType(actions.uploadActionSetImageSizeError):
      return {
        ...state,
        imageSizeError: action.payload,
      }
    case getType(actions.uploadActionSuccess):
      return {
        ...state,
        pending: true,
        preloader: false,
      }
    case getType(actions.uploadActionError):
      return {
        ...state,
        preloader: false,
      }
    case getType(actions.uploadActionNewImage):
      return {
        ...initialState,
        demoMode: state.demoMode,
      }
    case getType(actions.setDemoMode):
      return {
        ...state,
        demoMode: true,
      }
    case getType(actions.setCurrentBatchUpload):
      const { id, status } = action.payload
      return {
        ...state,
        currentBatchUpload: {
          ...state.currentBatchUpload,
          [status]: [...(state.currentBatchUpload[status] || []), id],
        },
      }
    case getType(actions.setUploadFiles):
      return {
        ...state,
        uploadFiles: [...state.uploadFiles, ...action.payload],
      }
    default:
      return state
  }
}
