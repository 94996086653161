import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/toast"
import { ToastPopUp } from "../types/toast"

type ToastState = Readonly<{
  openToasts: ToastPopUp[]
}>

const initialState: ToastState = {
  openToasts: [],
}

type ToastActions = ActionType<typeof actions>

export default (state = initialState, action: ToastActions): ToastState => {
  switch (action.type) {
    case getType(actions.setOpenToast):
      // Replace toast if type is similar (message could be different), otherwise add new toast
      const matchIndex = state.openToasts.findIndex(
        (o) => o.type === action.payload.type
      )
      return {
        ...state,
        openToasts:
          matchIndex === -1
            ? [
                ...state.openToasts,
                (state.openToasts[matchIndex] = action.payload),
              ]
            : state.openToasts.map((toast, index) =>
                index === matchIndex ? action.payload : toast
              ),
      }
    case getType(actions.removeOpenToast):
      return {
        ...state,
        openToasts: state.openToasts.filter(
          (toast) => toast.type !== action.payload
        ),
      }

    default:
      return state
  }
}
