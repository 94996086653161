import { RootState } from "core/store/configureStore"

export const getImageWidth = (state: RootState) => state.imageControls.width
export const getImageHeight = (state: RootState) => state.imageControls.height
export const getZoomScale = (state: RootState) => state.imageControls.scale
export const getActiveFilter = (state: RootState) =>
  state.imageControls.activeFilter
export const getIsImageFlipped = (state: RootState) =>
  state.serverData.present.imageMeta.isImageHorizontallyFlipped
export const getIsFullscreen = (state: RootState) =>
  state.imageControls.isFullscreen
export const getUsedFullscreen = (state: RootState) =>
  state.imageControls.usedFullscreen
export const getIsLargerAspectRatioScreen = (state: RootState) =>
  state.imageControls.isLargerAspectRatioScreen
export const getIsSmallScreen = (state: RootState) =>
  state.imageControls.isSmallScreen
export const getShowDynamicPbl = (state: RootState) =>
  state.imageControls.showDynamicPbl
export const getIsMeasurementToolActive = (state: RootState) =>
  state.imageControls.isMeasurementToolActive
export const getShouldResetZoom = (state: RootState) =>
  state.imageControls.shouldResetZoom
export const getIsEditingPblOnRadiograph = (state: RootState) =>
  state.imageControls.isEditingPblOnRadiograph
