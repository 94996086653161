import { RootState } from "core/store/configureStore"
import { createSelector } from "reselect"
import { getDataIsChanged } from "./saving"
import { Modals } from "../reducers/modalsReducer"

export const getOpenedModal = (state: RootState) => state.modals.openedModal
export const getNavigatingUrl = (state: RootState) => state.modals.navigatingUrl
export const getNewAIVersionModalShownIds = (state: RootState) =>
  state.modals.newAIVersionModalShownIds
export const getCurrentFaqTab = (state: RootState) => state.modals.currentFaqTab

export const getPreventShowingSaveChangesModal = createSelector(
  [getDataIsChanged, getOpenedModal],
  (dataIsChanged, openedModal) =>
    !dataIsChanged || openedModal === Modals.UNSAVED_CHANGES
)
