import { CloseFill } from "./CloseFill"
import { CariesProIcon } from "./CariesProIcon"
import { CloseOutline } from "./CloseOutline"
import { DetectionsIcon } from "./DetectionsIcon"
import { DrawIcon } from "./DrawIcon"
import { FlipIcon } from "./FlipIcon"
import { FullScreenIcon } from "./FullScreenIcon"
import { HiSensIcon } from "./HiSensIcon"
import { BrightnessAndSaturationIcon } from "./BrightnessAndSaturationIcon"
import { MeasureIcon } from "./MeasureIcon"
import { MoveIcon } from "./MoveIcon"
import { MoveToothIcon } from "./MoveToothIcon"
import { NewAnalysisIcon } from "./NewAnalysisIcon"
import { PerioProIcon } from "./PerioProIcon"
import { RedoIcon } from "./RedoIcon"
import { RotationIcon } from "./RotationIcon"
import { UndoIcon } from "./UndoIcon"
import { CheckmarkOutline } from "./CheckmarkOutline"
import { CheckmarkFill } from "./CheckmarkFill"
import { Circle } from "./Circle"
import { Processing } from "./Processing"
import { SelectToothIcon } from "./SelectToothIcon"
import { EraseIcon } from "./EraseIcon"
import { MoonIcon } from "./MoonIcon"
import { SunIcon } from "./SunIcon"
import { ContrastIcon } from "./ContrastIcon"
import { CrossIcon } from "./CrossIcon"
import { CloudUpload } from "./CloudUpload"
import { ActiveCheckbox } from "./ActiveCheckbox"
import { ArrowDropdownIcon } from "./ArrowDropdown"
import { FilterIcon } from "./FilterIcon"
import { ResearchUseOnlyIcon } from "./ResearchUseOnlyIcon"
import { MedicalDeviceIcon } from "./MedicalDeviceIcon"
import { InfoManualIcon } from "./InfoManualIcon"
import { UKCAMarkingIcon } from "./UKCAMarkinIcon"
import { CEIcon } from "./CEIcon"
import { ManufacturerIcon } from "./ManufacturerIcon"
import { DistributorIcon } from "./DistributorIcon"
import { ReleaseIcon } from "./ReleaseIcon"
import { REFIcon } from "./REFIcon"
import { UdiIcon } from "./UdiIcon"
import { DeuIcon } from "./DeuIcon"
import { ECREPIcon } from "./ECREPIcon"
import { CHREPIcon } from "./CHREPIcon"
import { WarningIcon } from "./WarningIcon"
import { Information } from "./Information"
import { ImageIcon, MoreVerticalIcon } from "@align-cpf/uikit-icons"
import { OrientationIcon } from "./OrientationIcon"
import { ArrangeIcon } from "./ArrangeIcon"
import { UngroupSetIcon } from "./UngroupSetIcon"
import { LaunchIcon } from "./LaunchIcon"

export default {
  cross: CrossIcon,
  fullScreen: FullScreenIcon,
  image: BrightnessAndSaturationIcon,
  contrast: ContrastIcon,
  detections: DetectionsIcon,
  rotation: RotationIcon,
  flip: FlipIcon,
  moveTooth: MoveToothIcon,
  draw: DrawIcon,
  selectTooth: SelectToothIcon,
  erase: EraseIcon,

  measure: MeasureIcon,
  hiSens: HiSensIcon,
  perioPro: PerioProIcon,
  cariesPro: CariesProIcon,
  newAnalysis: NewAnalysisIcon,
  undo: UndoIcon,
  redo: RedoIcon,
  move: MoveIcon,
  close: CloseOutline,
  closeFill: CloseFill,
  checkmark: CheckmarkOutline,
  checkmarkFill: CheckmarkFill,
  circle: Circle,
  processing: Processing,

  moon: MoonIcon,
  sun: SunIcon,
  upload: CloudUpload,
  ActiveCheckbox: ActiveCheckbox,
  Dropdown: ArrowDropdownIcon,
  FilterIcon: FilterIcon,
  ResearchUseOnly: ResearchUseOnlyIcon,
  MedicalDevice: MedicalDeviceIcon,
  InfoManual: InfoManualIcon,
  UKCAMarking: UKCAMarkingIcon,
  CE: CEIcon,
  Manufacturer: ManufacturerIcon,
  Distributor: DistributorIcon,
  Release: ReleaseIcon,
  REF: REFIcon,
  Udi: UdiIcon,
  Deu: DeuIcon,
  ECREP: ECREPIcon,
  CHREP: CHREPIcon,
  Warning: WarningIcon,
  Information: Information,
  moreVertical: MoreVerticalIcon,
  orientation: OrientationIcon,
  ImageIcon: ImageIcon,
  arrange: ArrangeIcon,
  ungroup: UngroupSetIcon,
  launch: LaunchIcon,
}
