import { takeEvery, put, select } from "redux-saga/effects"

import * as filtersActions from "library/common/actions/filters"
import * as filtersSelectors from "library/common/selectors/filters"
import { getAvailableFilters } from "../selectors/entities"
import localStorage from "library/utilities/localStorage"

import { filterTypes } from "library/common/types/filterTypes"
import { FiltersState, FilterStatus } from "../reducers/filtersReducer"
import { setActiveTooth } from "../actions/teeth"
import { getIsHighSenseModeActive } from "../selectors/image"
import { setUserInfo } from "../actions/user"
import { Kind } from "../types/serverDataTypes"
import { getKind } from "../selectors/serverData"
import { getFeatureRememberHsm } from "../selectors/features"

function* toggleFilterStatusSaga({
  payload: field,
}: ReturnType<typeof filtersActions.toggleFilterStatus>) {
  const state: FiltersState = yield select(filtersSelectors.getFiltersState)
  const availableFilters: Set<FilterStatus> = yield select(getAvailableFilters)

  const newState = { ...state, [field]: !state[field] }
  const allDisabled = Array.from(availableFilters).every((s) => !newState[s])

  if (field === FilterStatus.IS_ENABLED) {
    // if we enable the main toggle and everyone is disabled: enable them all
    if (newState.isEnabled && allDisabled) {
      availableFilters.forEach((s) => (newState[s] = true))
    }
  } else {
    // when transitioning from disable all to enabled:
    // only enable the specific field we clicked on
    if (!newState.isEnabled) {
      availableFilters.forEach((s) => (newState[s] = s === field))
      newState.isEnabled = true
    }
    // let the isEnabled button follow along
    else newState.isEnabled = !allDisabled
  }

  yield put(filtersActions.setFiltersStatus(newState))
}

function* deactivateActiveTooth() {
  const isHsmModeActive: boolean = yield select(getIsHighSenseModeActive)
  if (isHsmModeActive) yield put(setActiveTooth(null))
}

function* handleHsmSaga() {
  const isHsmModeActive: boolean = yield select(getIsHighSenseModeActive)
  const kind: Kind = yield select(getKind)

  if (isHsmModeActive && (kind === Kind.Bw || kind === Kind.Peri)) {
    yield put(setUserInfo({ cariesPro: true }))
  }

  const featureRememberHsm: boolean = yield select(getFeatureRememberHsm)

  if (!featureRememberHsm) return
  localStorage.setItem("HSM", isHsmModeActive)
}

export default function* filtersSaga() {
  yield takeEvery(filterTypes.TOGGLE_FILTER_STATUS, toggleFilterStatusSaga)
  yield takeEvery(filterTypes.TOGGLE_HSM, deactivateActiveTooth)

  yield takeEvery([filterTypes.TOGGLE_HSM, filterTypes.SET_HSM], handleHsmSaga)
}
