import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/serverData"
import { UserChange } from "library/common/types/dataStructureTypes"
import { AnnotationName, AnnotationOnTooth } from "../types/adjustmentTypes"
import {
  BoneLossFormUser,
  Comment,
  IInferenceStatus,
  ImageFilters,
  IMeta,
  INote,
  Kind,
} from "../types/serverDataTypes"
import { flipChanges, flipMovedTeeth } from "library/utilities/tooth"
import { flipTeeth, Tooth } from "@dentalxrai/transform-landmark-to-svg"
import withHistory from "./withHistory"

type ServerChangesState = Readonly<{
  changes: UserChange[]
  additions: AnnotationOnTooth[]
  addedTeeth: Tooth[]
  removedTeeth: Tooth[]
  movedTeeth: Record<string, number>
  generalComment: string
  addedComments: Comment[]
  forms: { boneLoss: BoneLossFormUser }
  imageMeta: IMeta
  inferenceStatus: IInferenceStatus
  imageFilters: ImageFilters
  notes: INote[]
}>

type ServerChangesActions = ActionType<typeof actions>

export const metaTemplate: IMeta = {
  patientID: "",
  patientName: "",
  dateOfBirth: "",
  analysisDate: "",
  imageDate: "",
  fileName: "",
  kind: Kind.Unknown,
  angleImageRotation: null,
  isImageHorizontallyFlipped: false,
  displayHorizontallyFlipped: false,
  imageWidth: 0,
  imageHeight: 0,
  readOnlyFields: {
    patientID: false,
    patientName: false,
    dateOfBirth: false,
    imageDate: false,
  },
  isOwner: true,
  features: [],
  outdatedAnalysis: false,
  imageFormatOrig: "",
  thumbnailImageWidth: null,
  thumbnailImageHeight: null,
  patientUuid: null,
  warnings: {},
}

export const boneLossFormTemplate: BoneLossFormUser = {
  toothLoss: "",
  toothLossOverride: false,
  maxBoneLossPercentCategoryOverride: "",
  boneLossIndexOverride: "",
  complications: "",
  distribution: "",
  age: 0,
  diabetes: "",
  smoking: "",
  changedTeeth: [],
}

export const initialServerDataState: ServerChangesState = {
  changes: [],
  additions: [],
  addedTeeth: [],
  removedTeeth: [],
  movedTeeth: {},
  generalComment: "",
  addedComments: [],
  forms: {
    boneLoss: boneLossFormTemplate,
  },
  imageMeta: metaTemplate,
  inferenceStatus: { status: "", message: "" },
  imageFilters: {
    contrast: 0,
    saturation: 0.5,
    brightness: 1,
  },
  notes: [],
}

const serverDataReducer = (
  state = initialServerDataState,
  action: ServerChangesActions
): ServerChangesState => {
  switch (action.type) {
    case getType(actions.addUserChanges):
      const { changes } = state

      const newChanges = changes.flatMap((change) => {
        const found =
          change.action !== "changed" &&
          action.payload.find(
            (candidate) => candidate.annotationId === change.annotationId
          )

        if (found) {
          if (
            // Do not delete hsm changes.
            change.isHSM ||
            found.isHSM ||
            // Do not delete rejected change if action is moved after rejecting it.
            (change.action === "rejected" && found.action === "moved") ||
            // Do not move back an annotation you moved, because you are rejecting or editing it now.
            (change.action === "moved" &&
              ["rejected", "changed"].includes(found.action))
          ) {
            return found.action === "changed"
              ? [change, found]
              : { ...change, ...found }
          } else {
            return found
          }
        } else {
          return change
        }
      })
      const newIds = new Set(
        newChanges
          .filter((change) => change.action !== "changed")
          .map((change) => change.annotationId)
      )
      const noDuplicatePayload = action.payload.filter(
        (change) => !newIds.has(change.annotationId)
      )

      return {
        ...state,
        changes: newChanges.concat(noDuplicatePayload),
      }
    case getType(actions.deleteUserChange):
      return {
        ...state,
        changes: state.changes
          .filter(
            (change: UserChange) =>
              change.annotationId !== action.payload.id ||
              (!action.payload.deleteNonHSM && !change.isHSM) || // only delete non HSM changes if deleteNonHSM is true.
              change.action === "changed" || // never remove these with this action
              change.newTooth // Moved should not be deleted here because it causes a side effect with moved AND rejected detections.
          )
          .map((change: UserChange) =>
            change.newTooth && change.annotationId === action.payload.id
              ? { ...change, action: "moved" }
              : change
          ),
      }
    case getType(actions.editUserChange):
      const { oldChange, newChange } = action.payload

      return {
        ...state,
        changes: state.changes.map((c) => (c === oldChange ? newChange : c)),
      }
    case getType(actions.deleteMovedUserChange):
      return {
        ...state,
        changes: state.changes.filter(
          (change) => action.payload !== change.newTooth
        ),
      }
    case getType(actions.addUserAdditions):
      const fixLegacyType = (n: AnnotationName) =>
        n === AnnotationName.restoration ? AnnotationName.restorations : n
      const existingIds = state.additions.map((a) => a.id)
      const newAdditions = [...state.additions]
      action.payload.forEach((addition) => {
        const a = {
          ...addition,
          type: fixLegacyType(addition.type),
          id: addition.id ?? Math.random(), // we need a unique id for every annotation
        }

        // if there is an existing annotation with this ID, overwrite it
        // otherwise add new annotation to the end
        const match = existingIds.indexOf(a.id)
        if (match >= 0) {
          // overwrite / merge with existing annotation
          newAdditions[match] = { ...newAdditions[match], ...a }
        } else {
          // add new annotation at the end
          newAdditions.push(a)
        }
      })
      return {
        ...state,
        additions: newAdditions,
      }

    case getType(actions.changeUserAddition):
      const { id } = action.payload
      const additions = state.additions.map((a) =>
        a.id === id ? action.payload : a
      )

      return { ...state, additions }

    case getType(actions.deleteUserAddition): {
      return {
        ...state,
        additions: state.additions.filter(
          (candidate: AnnotationOnTooth) =>
            candidate.toothName !== action.payload.toothName ||
            candidate.type !== action.payload.type ||
            candidate.subtype !== action.payload.subtype ||
            (action.payload.location &&
              candidate.location !== action.payload.location)
        ),
      }
    }
    case getType(actions.deleteUserAdditionByIdSuccess): {
      return {
        ...state,
        additions: state.additions.filter(
          (candidate: AnnotationOnTooth) => candidate.id !== action.payload
        ),
      }
    }
    case getType(actions.userAddAddedTeeth):
      return { ...state, addedTeeth: [...state.addedTeeth, ...action.payload] }
    case getType(actions.userDeleteAddedTeeth):
      return {
        ...state,
        addedTeeth: state.addedTeeth.filter(
          (tooth: Tooth) => tooth.toothName !== action.payload
        ),
      }
    case getType(actions.userAddDeletedTeeth):
      // Filter duplicates.
      const newRemovedTeeth = action.payload.filter(
        (tooth) =>
          !state.removedTeeth.some(
            (candidate) => candidate.toothName === tooth.toothName
          )
      )

      return {
        ...state,
        removedTeeth: [...state.removedTeeth, ...newRemovedTeeth],
      }
    case getType(actions.userDeleteDeletedTeeth):
      return {
        ...state,
        removedTeeth: state.removedTeeth.filter(
          (tooth: Tooth) => tooth.toothName !== action.payload
        ),
      }
    case getType(actions.setMovedTeeth):
      return {
        ...state,
        movedTeeth: action.payload,
      }
    case getType(actions.setInitialState): {
      return initialServerDataState
    }
    case getType(actions.setInitialImageMetaState): {
      return { ...state, imageMeta: metaTemplate }
    }
    case getType(actions.setGeneralComment):
      return {
        ...state,
        generalComment: action.payload,
      }

    case getType(actions.addNote):
      return {
        ...state,
        notes: state.notes.concat({
          text: state.generalComment,
          created: new Date().toISOString(),
        }),
      }
    case getType(actions.setNotes):
      return {
        ...state,
        notes: [...state.notes, ...action.payload],
      }
    case getType(actions.setToothLoss):
      return {
        ...state,
        forms: {
          ...state.forms,
          boneLoss: {
            ...state.forms.boneLoss,
            toothLoss: action.payload,
            toothLossOverride: true,
          },
        },
      }

    case getType(actions.setBoneLossCategory):
      return {
        ...state,
        forms: {
          ...state.forms,
          boneLoss: {
            ...state.forms.boneLoss,
            maxBoneLossPercentCategoryOverride: action.payload,
          },
        },
      }

    case getType(actions.setBoneLossIndex):
      return {
        ...state,
        forms: {
          ...state.forms,
          boneLoss: {
            ...state.forms.boneLoss,
            boneLossIndexOverride: action.payload,
          },
        },
      }

    case getType(actions.setToothBoneloss):
      const { toothName } = action.payload
      return {
        ...state,
        forms: {
          ...state.forms,
          boneLoss: {
            ...state.forms.boneLoss,
            changedTeeth: [
              ...state.forms.boneLoss.changedTeeth.filter(
                (i) => i.toothName != toothName
              ),
              action.payload,
            ],
          },
        },
      }

    case getType(actions.setAge):
      return {
        ...state,
        forms: {
          ...state.forms,
          boneLoss: {
            ...state.forms.boneLoss,
            age: action.payload,
          },
        },
      }

    case getType(actions.setDiabetes):
      return {
        ...state,
        forms: {
          ...state.forms,
          boneLoss: {
            ...state.forms.boneLoss,
            diabetes: action.payload,
          },
        },
      }

    case getType(actions.setDistribution):
      return {
        ...state,
        forms: {
          ...state.forms,
          boneLoss: {
            ...state.forms.boneLoss,
            distribution: action.payload,
          },
        },
      }

    case getType(actions.setSmoking):
      return {
        ...state,
        forms: {
          ...state.forms,
          boneLoss: {
            ...state.forms.boneLoss,
            smoking: action.payload,
          },
        },
      }

    case getType(actions.setComplications):
      return {
        ...state,
        forms: {
          ...state.forms,
          boneLoss: {
            ...state.forms.boneLoss,
            complications: action.payload,
          },
        },
      }

    case getType(actions.addAddedComments):
      const newToothNames = new Set(action.payload.map((c) => c.toothName))
      const addedComments = state.addedComments
        .filter((c) => !newToothNames.has(c.toothName))
        .concat(action.payload.filter((c) => !!c.comment))

      return {
        ...state,
        addedComments,
      }
    case getType(actions.saveImageMeta):
      return {
        ...state,
        imageMeta: {
          ...action.payload,
          kind: action.payload.kind || Kind.Unknown,
        },
      }

    case getType(actions.saveBoneLossForm):
      return {
        ...state,
        forms: {
          ...state.forms,
          boneLoss: action.payload,
        },
      }

    case getType(actions.toggleFlipImage):
      // Flipping modifications are done in-place
      const isFlipped = !state.imageMeta.isImageHorizontallyFlipped
      return {
        ...state,
        changes: flipChanges(state.changes),
        additions: flipTeeth(state.additions),
        addedTeeth: flipTeeth(state.addedTeeth),
        removedTeeth: flipTeeth(state.removedTeeth),
        addedComments: flipTeeth(state.addedComments),
        movedTeeth: flipMovedTeeth(state.movedTeeth),
        forms: {
          ...state.forms,
          boneLoss: {
            ...state.forms.boneLoss,
            changedTeeth: flipTeeth(state.forms.boneLoss.changedTeeth),
          },
        },
        imageMeta: {
          ...state.imageMeta,
          isImageHorizontallyFlipped: isFlipped,
        },
      }
    // Move the comments alongside the moving teeth
    case getType(actions.moveComments):
      const { oldTeeth, movedTeeth } = action.payload
      const updatedAddedComments = state.addedComments.map((comment) => {
        const index = oldTeeth.indexOf(comment.toothName)
        return index >= 0
          ? { ...comment, toothName: movedTeeth[index] }
          : comment
      })
      return {
        ...state,
        addedComments: updatedAddedComments,
      }

    case getType(actions.toggleDisplayHorizontallyFlipped):
      return {
        ...state,
        imageMeta: {
          ...state.imageMeta,
          displayHorizontallyFlipped:
            !state.imageMeta.displayHorizontallyFlipped,
        },
      }

    case getType(actions.setInferenceStatus):
      return { ...state, inferenceStatus: action.payload }
    case getType(actions.setPatientUuid):
      return {
        ...state,
        imageMeta: { ...state.imageMeta, patientUuid: action.payload },
      }

    // Image filters lives here to allow undo/redo of stack
    case getType(actions.adjustFilter):
      const { type, value } = action.payload
      return {
        ...state,
        imageFilters: {
          ...state.imageFilters,
          [type]: value,
        },
      }
    default:
      return state
  }
}

export default withHistory<ServerChangesState, ServerChangesActions>(
  serverDataReducer
)
