export enum SavingTypes {
  REQUEST_SEND_CHANGES = "@@SAVING/REQUEST_SEND_CHANGES",
  REQUEST_SEND_CHANGES_COMPLETE = "@@SAVING/REQUEST_SEND_CHANGES_COMPLETE",
  SET_DATA_IS_CHANGED = "@@SAVING/SET_DATA_IS_CHANGED",
  REQUEST_SEND_EVENT = "@@SAVING/SEND_EVENT",
  SET_SAVING_RESULT_STATUS = "@@SAVING/SET_SAVING_RESULT_STATUS",
  SET_NOTIFICATION_DISMISSED = "@@SAVING/SET_NOTIFICATION_DISMISSED",
}

export interface SaveComplete {
  success: boolean
  savedWithButton: boolean
  id: string
}

export enum SavingType {
  ALL = "all",
  IMAGE_META_DATA = "meta",
}
export enum DismissalType {
  autoRotationDismissed = "autoRotationDismissed",
  orthoConditionDismissed = "orthoConditionDismissed",
}
export interface DismissedNotification {
  id: string
  type: DismissalType
}

export interface SavingData {
  savingChanges: SavingType
  savedWithButton?: boolean
  resultId: string
}
