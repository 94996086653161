import { createAction } from "typesafe-actions"

import { ImageControlsTypes } from "../types/imageControlsTypes"

import { ActiveFilter } from "library/common/reducers/imageControlsReducer"

export const setActiveFilter = createAction(
  ImageControlsTypes.SET_ACTIVE_FILTER
)<ActiveFilter>()
export const setActiveFilterSuccess = createAction(
  ImageControlsTypes.SET_ACTIVE_FILTER_SUCCESS
)<ActiveFilter>()
export const setInitialState = createAction(
  ImageControlsTypes.SET_INITIAL_STATE
)()
export const setIsLargerAspectRatioScreen = createAction(
  ImageControlsTypes.SET_IS_LARGER_ASPECT_RATIO_SCREEN
)<boolean>()

export const setIsSmallScreen = createAction(
  ImageControlsTypes.SET_IS_SMALL_SCREEN
)<boolean>()

export const setZoom = createAction(ImageControlsTypes.SET_ZOOM)<number>()

export const setDimensions = createAction(ImageControlsTypes.SET_DIMENSIONS)<{
  width: number
  height: number
}>()

export const toggleFullscreen = createAction(
  ImageControlsTypes.TOGGLE_FULLSCREEN
)()

export const setShowDynamicPbl = createAction(
  ImageControlsTypes.SET_SHOW_DYNAMIC_PBL
)<boolean>()

export const toggleIsMeasurementToolActive = createAction(
  ImageControlsTypes.TOGGLE_IS_MEASUREMENT_TOOL_ACTIVE
)()

export const setShouldResetZoom = createAction(
  ImageControlsTypes.SET_SHOULD_RESET_ZOOM
)<boolean>()
export const setIsEditingPblOnRadiograph = createAction(
  ImageControlsTypes.SET_IS_EDITING_PBL_ON_RADIOGRAPH
)<boolean>()
