import { Theme } from "library/common/actions/user"
import {
  AnnotationName,
  RestorationSubtype,
} from "library/common/types/adjustmentTypes"

export const DEFAULT_THEME =
  (process.env.REACT_APP_DEFAULT_THEME as Theme) || Theme.dentalxrai

export const WHATS_NEW_VERSION = DEFAULT_THEME == Theme.dentalxrai ? "3" : "2"

export const DEFAULT_COACH_MARKS = {
  cariesPro: 0,
  boneLossPro: 0,
  calculusXrayImage: 0,
  calculusToothMap: 0,
  calculusEditView: 0,
  cariesOnOpg: 0,
  autoCariesPro: 0,
}

export const SUBTYPE_RGB = {
  bridges: {
    red: 110,
    green: 229,
    blue: 255,
  },
  crowns: {
    red: 14,
    green: 180,
    blue: 217,
  },
}

export const BONELOSS_COLORS = {
  low: "#d1d1d1",
  medium: "#ffba66",
  high: "#ff8080",
}

export const MONO_BONELOSS_COLOR = "#8a80ff"

// These subtypes cannot exist together with crowns or implants
const BASE_DISABLING = [
  AnnotationName.apical,
  AnnotationName.caries,
  RestorationSubtype.fillings,
  AnnotationName.impacted,
  AnnotationName.nervus,
  RestorationSubtype.roots,
]

export const DISABLING_CASES = {
  calculus: [RestorationSubtype.bridges],
  crowns: [RestorationSubtype.bridges],
  apical: [RestorationSubtype.implants, RestorationSubtype.bridges],
  caries: [RestorationSubtype.implants, RestorationSubtype.bridges],
  fillings: [RestorationSubtype.implants, RestorationSubtype.bridges],
  impacted: [RestorationSubtype.implants, RestorationSubtype.bridges],
  nervus: [RestorationSubtype.implants, RestorationSubtype.bridges],
  roots: [RestorationSubtype.implants, RestorationSubtype.bridges],
  implants: BASE_DISABLING.concat(RestorationSubtype.bridges),
  bridges: BASE_DISABLING.concat(
    AnnotationName.calculus,
    RestorationSubtype.crowns,
    RestorationSubtype.implants
  ),
}

export const CARIES_OPACITY = 0.75
export const TOOTH_MAP_POSITION_ASPECT_RATIO = 1.85

export const DEVICE_ID = process.env.REACT_APP_DEVICE_ID || "4260739410006"
export const PRODUCT_NAME = process.env.REACT_APP_TITLE
export const IS_STORYBOOK = process.env.STORYBOOK // Provided by Storybook

export const DETECT_BROWSER_LANGUAGE =
  !!process.env.REACT_APP_DETECT_BROWSER_LANGUAGE

export const SUPPORTED_LANGUAGES =
  process.env.REACT_APP_SUPPORTED_LANGUAGES?.split(",") || ["en"]
export const OAUTH_PROVIDER_NAME = process.env.REACT_APP_OAUTH_PROVIDER_NAME
export const OAUTH_BASE_URI = process.env.REACT_APP_OAUTH_BASE_URI
export const RENEW_SUBSCRIPTION_URL =
  process.env.REACT_APP_RENEW_SUBSCRIPTION_URL
export const AUTH0_PROVIDER_NAME = process.env.REACT_APP_AUTH0_PROVIDER_NAME
export const AUTH0_BASE_URI = process.env.REACT_APP_AUTH0_BASE_URI
export const RENEW_SUBSCRIPTION_URL_AUTH0 =
  process.env.REACT_APP_RENEW_SUBSCRIPTION_URL_AUTH0
export const SHOW_PENDO_INTEGRATION =
  !!process.env.REACT_APP_SHOW_PENDO_INTEGRATION

export const ANNOTATION_HEX_COLORS = {
  caries: "#FF2800",
  apical: "#FF8C00",
  calculus: "#ffec00",
  bridges: "#6EE5FF",
  crowns: "#12B3D9",
  fillings: "#355EF2",
  roots: "#8a80ff",
  annotate: "#FFFFFF",
  select: "#FFFFFF",
  none: "#525252",
  nervus: "#B7F63A",
  impacted: "#ff00ed",
  implant: "#6fa6ff",
  boneloss: "#8a80ff",
}
