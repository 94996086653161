import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import { isBeta } from "library/utilities/beta"
import localStorage from "library/utilities/localStorage"
import de from "resources/locales/de.json"
import deBeta from "resources/locales/deBeta.json"
import en from "resources/locales/en.json"
import enBeta from "resources/locales/enBeta.json"
import es from "resources/locales/es.json"
import esBeta from "resources/locales/esBeta.json"
import frCa from "resources/locales/fr-CA.json"
import fr from "resources/locales/fr.json"
import frBeta from "resources/locales/frBeta.json"
import it from "resources/locales/it.json"
import itBeta from "resources/locales/itBeta.json"
import nl from "resources/locales/nl.json"
import nlBeta from "resources/locales/nlBeta.json"
import pl from "resources/locales/pl.json"
import plBeta from "resources/locales/plBeta.json"
import pt from "resources/locales/pt.json"
import ptBeta from "resources/locales/ptBeta.json"
import bg from "resources/locales/bg.json"
import cs from "resources/locales/cs.json"
import da from "resources/locales/da.json"
import el from "resources/locales/el.json"
import et from "resources/locales/et.json"
import fi from "resources/locales/fi.json"
import hr from "resources/locales/hr.json"
import hu from "resources/locales/hu.json"
import lt from "resources/locales/lt.json"
import lv from "resources/locales/lv.json"
import ro from "resources/locales/ro.json"
import sk from "resources/locales/sk.json"
import sl from "resources/locales/sl.json"
import sv from "resources/locales/sv.json"
import tr from "resources/locales/tr.json"
import version from "resources/locales/version.json"
import { supportedLanguages } from "./utils"
import { DETECT_BROWSER_LANGUAGE } from "library/utilities/constants"

let locale = localStorage.getItem("locale")

if (!locale && DETECT_BROWSER_LANGUAGE) {
  locale = window.navigator.language.slice(0, 2)
}

const lng = supportedLanguages.includes(locale) ? locale : supportedLanguages[0]

function mergeDictionary(a: Record<string, any> | undefined, b: object) {
  // merge b into a
  const result = { ...a }

  Object.entries(b).forEach(([k, value]) => {
    result[k] =
      value.constructor === Object ? mergeDictionary(result[k], value) : value
  })

  return result
}

// keep this alphabetical to make it easier to compare with the list of JSON files we have
const baseTranslations = {
  bg,
  cs,
  da,
  de,
  deBeta,
  el,
  en,
  enBeta,
  es,
  esBeta,
  et,
  fi,
  fr,
  frBeta,
  "fr-CA": frCa, // Desired format for language codes (https://www.i18next.com/how-to/faq#how-should-the-language-codes-be-formatted)
  hr,
  hu,
  it,
  itBeta,
  lv,
  lt,
  nl,
  nlBeta,
  pl,
  plBeta,
  pt,
  ptBeta,
  ro,
  sk,
  sl,
  sv,
  tr,
}

const enTranslations = isBeta
  ? mergeDictionary(baseTranslations.en, baseTranslations.enBeta)
  : baseTranslations.en

const resources = {}
supportedLanguages.forEach((lang) => {
  const base = mergeDictionary(
    enTranslations,
    mergeDictionary(
      baseTranslations[lang as keyof typeof baseTranslations],
      version
    )
  )
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  resources[lang] = {
    translation: isBeta
      ? mergeDictionary(
          base,
          baseTranslations[(lang + "Beta") as keyof typeof baseTranslations]
        )
      : base,
  }
})

export default {
  promise: i18n.use(initReactI18next).init({
    compatibilityJSON: "v4",
    lng,
    resources,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: [
        "br",
        "strong",
        "i",
        "ul",
        "ol",
        "li",
        "h1",
        "h2",
        "h3",
        "hr",
        "p",
      ],
    },
    interpolation: {
      escapeValue: false,
    },
  }),
  i18n,
}
