import * as types from "./types"

import localStorage from "library/utilities/localStorage"

interface II18nReducerAction {
  type: string
  // if you add a new language, add it also to `supportedLanguages` in "core/i18n/utils"
  lang:
    | "bg"
    | "cs"
    | "da"
    | "de"
    | "el"
    | "en"
    | "es"
    | "et"
    | "fi"
    | "fr"
    | "fr-CA"
    | "hr"
    | "hu"
    | "it"
    | "lt"
    | "lv"
    | "nl"
    | "pl"
    | "pt"
    | "ro"
    | "sk"
    | "sl"
    | "sv"
    | "tr"
}

const initialState = {
  currLang: localStorage.getItem("locale") || "en",
}

export default (state = initialState, action: II18nReducerAction) => {
  switch (action.type) {
    case types.SET_LANG:
      return {
        ...state,
        currLang: action.lang,
      }

    default:
      return state
  }
}
