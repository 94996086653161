import { ActionType, getType } from "typesafe-actions"

import * as actions from "../actions/patient"
import {
  FilterState,
  ActivePatientResult,
  PatientListResult,
  PatientMatch,
  PatientListSorting,
  SetPositioning,
} from "../types/patientTypes"
import { filterSections } from "pages/Patients/PatientList/filterSections"
import { ResultStatus } from "../types/dataStructureTypes"
import { templateAmount } from "library/utilities/patients"

type PatientState = Readonly<{
  patientListResult: PatientListResult
  activePatientResult: ActivePatientResult
  filterValues: FilterState[]
  isPatientMatchingMode: boolean
  patientMatch: PatientMatch | null
  patientListSorting: PatientListSorting | null
}>
export const initialFilters = filterSections.map((s) => ({
  section: s.heading.value,
  selected: [],
  match: s.match,
}))

export const initialPatientState: PatientState = {
  patientListResult: {
    resultStatus: ResultStatus.none,
    patientList: [],
  },
  activePatientResult: {
    resultStatus: ResultStatus.none,
    images: null,
    patient: null,
    links: [],
    radiographSets: [],
    activeRadiographSet: null,
    activePatientListItem: null,
    isAccordionOpen: false,
    isEditSetActive: false,
  },
  filterValues: initialFilters,
  isPatientMatchingMode: false,
  patientMatch: null,
  patientListSorting: { key: "imageDate", sortDirection: "DESC" },
}

type PatientActions = ActionType<typeof actions>

export default (
  state = initialPatientState,
  action: PatientActions
): PatientState => {
  switch (action.type) {
    case getType(actions.setInitialState): {
      return {
        ...initialPatientState,
        patientMatch: state.patientMatch,
        isPatientMatchingMode: state.isPatientMatchingMode,
        patientListSorting: state.patientListSorting,
      }
    }
    case getType(actions.setPatientListResult): {
      return {
        ...state,
        patientListResult: action.payload,
      }
    }
    case getType(actions.setPatientListResultStatus): {
      return {
        ...state,
        patientListResult: {
          ...state.patientListResult,
          resultStatus: action.payload,
        },
      }
    }

    case getType(actions.setActivePatientResult): {
      return {
        ...state,
        activePatientResult: action.payload,
      }
    }

    case getType(actions.clearActivePatientResult): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          resultStatus: ResultStatus.none,
          images: null,
          patient: null,
          links: [],
          radiographSets: [],
          activeRadiographSet: null,
          activePatientListItem: null,
        },
      }
    }

    case getType(actions.setActivePatientResultStatus): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          resultStatus: action.payload,
        },
      }
    }
    case getType(actions.setPatientResultStatus): {
      const patient = state.activePatientResult.patient

      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(patient && {
            patient: {
              ...patient,
              resultStatus: action.payload,
            },
          }),
        },
      }
    }

    case getType(actions.setConfirmedAnnotations): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          images:
            state.activePatientResult.images?.map((image) =>
              image.resultId ===
              state.activePatientResult.activePatientListItem?.id
                ? {
                    ...image,
                    confirmedAnnotations: action.payload,
                  }
                : image
            ) || [],
        },
      }
    }
    case getType(actions.setActivePatientListItem): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          activePatientListItem: action.payload,
        },
      }
    }

    case getType(actions.toggleAccordion): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          isAccordionOpen: action.payload,
        },
      }
    }

    case getType(actions.deleteActivePatientXray): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          images:
            state.activePatientResult.images?.filter(
              (a) => a.resultId !== action.payload
            ) || [],
        },
      }
    }
    case getType(actions.setFilterValues): {
      return {
        ...state,
        filterValues: action.payload,
      }
    }
    case getType(actions.setIsPatientMatchingMode): {
      return {
        ...state,
        isPatientMatchingMode: action.payload,
      }
    }
    case getType(actions.setPatientMatch): {
      return {
        ...state,
        patientMatch: action.payload,
      }
    }
    case getType(actions.setPatientListSorting): {
      return {
        ...state,
        patientListSorting: action.payload,
      }
    }
    case getType(actions.setActiveRadiographSet): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          activeRadiographSet: action.payload,
        },
      }
    }
    case getType(actions.replaceRadiographSetImage): {
      const { position, resultId } = action.payload

      const activeRadiographSet = state.activePatientResult.activeRadiographSet
      const changes = activeRadiographSet?.changes || []
      const radiographs = activeRadiographSet?.radiographs || []
      const currentReplacingImage = activeRadiographSet?.activeSetImage

      if (!currentReplacingImage) return state

      // Update change if it exists, else add a new change
      const updatedChanges = changes.some((a) => a.position === position)
        ? changes.map((a) =>
            a.position === position
              ? {
                  ...a,
                  resultId: resultId,
                }
              : a
          )
        : [...changes, { position, resultId }]

      // Update the radiographs array with the new resultId
      const updatedRadiographs = () => {
        return changes.some((a) => a.position === position)
          ? changes.map((a) =>
              a.position === position ? resultId : a.resultId
            )
          : radiographs.map((r, i) => (i === position ? resultId : r))
      }

      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(activeRadiographSet && {
            activeRadiographSet: {
              ...activeRadiographSet,
              radiographs: updatedRadiographs(),
              changes: updatedChanges,
              activeSetImage: {
                position,
                resultId,
              },
            },
          }),
        },
      }
    }

    case getType(actions.setPatientMetadata): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...action.payload,
        },
      }
    }

    // Set the metadata for a specific image within images
    case getType(actions.setPatientImageMeta): {
      const { id, meta } = action.payload
      const images = state.activePatientResult.images

      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(images && {
            images: images.map((i) =>
              i.resultId === id ? { ...i, imageMetadata: { ...meta } } : i
            ),
          }),
        },
      }
    }
    case getType(actions.setActiveSetImage): {
      const activeRadiographSet = state.activePatientResult.activeRadiographSet
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(activeRadiographSet && {
            activeRadiographSet: {
              ...activeRadiographSet,
              activeSetImage: action.payload,
            },
          }),
        },
      }
    }
    case getType(actions.setActiveSetImageId): {
      const activeRadiographSet = state.activePatientResult.activeRadiographSet
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(activeRadiographSet?.activeSetImage && {
            activeRadiographSet: {
              ...activeRadiographSet,
              activeSetImage: {
                ...activeRadiographSet.activeSetImage,
                resultId: action.payload,
              },
            },
          }),
        },
      }
    }
    case getType(actions.setIsEditSetActive): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          isEditSetActive: action.payload,
        },
      }
    }
    case getType(actions.updateRadiographPosition): {
      const { currentRadiograph, newPosition } = action.payload
      const activeRadiographSet = state.activePatientResult.activeRadiographSet
      const radiographs = activeRadiographSet?.radiographs || []

      function updatedChanges(): SetPositioning[] {
        let changes = activeRadiographSet?.changes || []

        const findResultIdByPosition = (position: number) =>
          activeRadiographSet?.radiographs.find((_, i) => i === position)

        const findChangeByPosition = (position: number) => {
          return changes.find((change) => change.position === position)
        }

        const findChangeByResultId = (resultId: string) => {
          return changes.find((change) => change.resultId === resultId)
        }

        const changeAtNewPosition = findChangeByPosition(newPosition)

        const updateChanges = (
          comparePosition: number,
          addedPosition: number
        ) =>
          changes.map((c) =>
            c.position === comparePosition
              ? { ...c, position: addedPosition }
              : c
          )

        const newlyAddedChangeWithResultId = {
          position: currentRadiograph.position,
          resultId: findResultIdByPosition(newPosition) || "",
        }

        const newlyAddedChange = {
          resultId: currentRadiograph.resultId,
          position: newPosition,
        }

        // Check if the new position is occupied by an image
        const imageInPosition =
          changeAtNewPosition ||
          !findChangeByResultId(findResultIdByPosition(newPosition) || "")

        // If new position is an empty spot
        if (!imageInPosition) {
          if (findChangeByResultId(currentRadiograph.resultId)) {
            changes = updateChanges(currentRadiograph.position, newPosition)
          } else {
            changes.push(newlyAddedChange)
          }
          // If new position is already occupied by another image
        } else {
          // If new position is in changes
          if (changeAtNewPosition) {
            changes = updateChanges(newPosition, currentRadiograph.position)
            changes = findChangeByResultId(currentRadiograph.resultId)
              ? changes.map((c) =>
                  c.resultId === currentRadiograph.resultId
                    ? { ...c, position: newPosition }
                    : c
                )
              : changes.concat(newlyAddedChange)
          }
          // If current position is in changes
          else if (findChangeByPosition(currentRadiograph.position)) {
            changes = updateChanges(currentRadiograph.position, newPosition)
            changes = changes.concat(newlyAddedChangeWithResultId)
          } else {
            // If neither the current position nor the new position is in changes
            changes.push(newlyAddedChange, newlyAddedChangeWithResultId)
          }
        }

        /*
          As soon as we move a radiograph, we add all other radiographs into the changes array
          as the user makes the conscious decision to create a gap in the grid (empty spot)
        */
        const newChanges = changes.filter((c) => c.resultId)
        const newChangesIds = newChanges.map((c) => c.resultId)

        // We first create an object with positioning and then filter, to ensure that the order is kept
        const leftoverChanges = radiographs
          .map((a, i) => ({
            resultId: a,
            position: i,
          }))
          .filter((r) => !newChangesIds.includes(r.resultId))

        return newChanges.concat(leftoverChanges)
      }

      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(activeRadiographSet && {
            activeRadiographSet: {
              ...activeRadiographSet,
              changes: updatedChanges(),
              activeSetImage: !action.payload.updateActiveSetImage
                ? updatedChanges().find((a) => a.position === newPosition) ||
                  null
                : activeRadiographSet.activeSetImage,
            },
          }),
        },
      }
    }
    case getType(actions.removeRadiographFromSet): {
      const filteredRadiographs = (radiographs?: SetPositioning[] | null) =>
        radiographs?.filter((r) => r.resultId !== action.payload) || []
      const activeRadiographSet = state.activePatientResult.activeRadiographSet
      const changes = activeRadiographSet?.changes || []

      const filteredRadiographIds = activeRadiographSet!.radiographs.filter(
        (r) => r !== action.payload
      )

      // Get the statuses of the radiographs that are still in the set
      const activeRadiographSetStatuses = [
        ...new Set(
          state.activePatientResult.images
            ?.filter((a) => filteredRadiographIds.includes(a.resultId || ""))
            .map((a) => a.status)
        ),
      ]

      const newActiveSetImage = !!changes.length
        ? filteredRadiographs(changes)[0]
        : { resultId: filteredRadiographIds[0] || "", position: 0 }

      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(activeRadiographSet && {
            activeRadiographSet: {
              ...activeRadiographSet,
              changes: filteredRadiographs(activeRadiographSet?.changes),
              radiographs: filteredRadiographIds,
              activeSetImage: newActiveSetImage,
              statuses: activeRadiographSetStatuses,
            },
          }),
        },
      }
    }
    case getType(actions.addRadiographToSet): {
      const activeRadiographSet = state.activePatientResult.activeRadiographSet
      const activeRadiographSetChanges = activeRadiographSet?.changes || []
      const activeRadiographSetChangesIds = activeRadiographSetChanges.map(
        (a) => a.resultId
      )
      const radiographs = activeRadiographSet?.radiographs || []
      const availablePositions = [...Array(templateAmount(activeRadiographSet))]
        .map((_, i) => i)
        .filter(
          (a) =>
            !radiographs?.some((r, index) => {
              return !activeRadiographSetChangesIds.includes(r) && index === a
            }) && !activeRadiographSetChanges.find((c) => c.position === a)
        )
      const firstAvailablePosition = Math.min(...availablePositions)

      const newRadiographStatus = state.activePatientResult.images?.find(
        (i) => i.resultId === action.payload
      )?.status

      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...(activeRadiographSet && {
            activeRadiographSet: {
              ...activeRadiographSet,
              radiographs: [...radiographs, action.payload],
              ...(activeRadiographSetChanges.length > 0 && {
                changes: [
                  ...activeRadiographSetChanges,
                  {
                    position: firstAvailablePosition,
                    resultId: action.payload,
                  },
                ],
              }),
              ...(newRadiographStatus && {
                statuses: [
                  ...new Set(
                    activeRadiographSet.statuses?.concat(newRadiographStatus)
                  ),
                ],
              }),
            },
          }),
        },
      }
    }

    default:
      return state
  }
}
