import { Modals } from "../reducers/modalsReducer"

export enum ModalTypes {
  OPEN_MODAL = "@@MODAL/OPEN_MODAL",
  CLOSE_MODAL = "@@MODAL/CLOSE_MODAL",
  SET_NEW_AI_VERSION_MODAL_SHOWN_IDS = "@@MODAL/SET_NEW_AI_VERSION_MODAL_SHOWN_IDS",
}

// Rearrange to change the help modal button order
export enum HelpModalEntry {
  LEGEND = "legend",
  ANALYSIS = "analysis",
  NAVIGATION = "navigation",
  WHATS_NEW = "new",
  LANGUAGES = "language",
  USER_MANUAL = "manual",
  PRODUCT_INFORMATION = "info",
  INVISALIGN = "invisalign",
  REPORT_PROBLEM = "problem",
}

export type ModalType = {
  openedModal: Modals
  currentFaqTab?: HelpModalEntry
  navigatingUrl?: string
}
