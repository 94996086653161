import localStorage from "library/utilities/localStorage"
import i18n from "i18next"
import { SUPPORTED_LANGUAGES } from "library/utilities/constants"
import { Theme } from "library/common/actions/user"

export const supportedLanguages = SUPPORTED_LANGUAGES

export const setLanguage = (
  language: string | null,
  setLanguage: (lang: string) => void,
  theme: string
) => {
  const saveLang = (lang: string) => {
    localStorage.setItem("locale", lang)
    void i18n.changeLanguage(lang)
    setLanguage(lang)
  }

  // Ensure that we support language codes with regions e.g. en-US or en-GB
  const parsedLang = language && language.slice(0, 2)
  const supported = parsedLang && supportedLanguages.includes(parsedLang)
  if (supported) {
    // Set the language if it's supported
    saveLang(parsedLang)
  } else if (theme === Theme.carestream && language) {
    // for carestream we just set it to English when we receive an unknown / unsupported language
    saveLang("en")
  }
}

export const languageNames = [
  { title: "Български", value: "bg" },
  { title: "český", value: "cs" },
  { title: "Dansk", value: "da" },
  { title: "Deutsch", value: "de" },
  { title: "Ελληνικά", value: "el" },
  { title: "English", value: "en" },
  { title: "Español", value: "es" },
  { title: "Eesti keel", value: "et" },
  { title: "Français Canadien", value: "fr-CA" },
  { title: "Français", value: "fr" },
  { title: "Hrvatski", value: "hr" },
  { title: "Italiano", value: "it" },
  { title: "latviski", value: "lv" },
  { title: "lietuvių", value: "lt" },
  { title: "Magyar", value: "hu" },
  { title: "Nederlands", value: "nl" },
  { title: "Polski", value: "pl" },
  { title: "Português", value: "pt" },
  { title: "Română", value: "ro" },
  { title: "Slovenský", value: "sk" },
  { title: "Slovenščina", value: "sl" },
  { title: "Suomi", value: "fi" },
  { title: "Svenska", value: "sv" },
  { title: "Türkçe", value: "tr" },
].filter((l) => SUPPORTED_LANGUAGES.includes(l.value))
