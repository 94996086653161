import { RootState } from "core/store/configureStore"

export const getFeatureNerveCanal = (state: RootState) =>
  state.features.featureNerveCanal
export const getFeatureFmx = (state: RootState) => state.features.featureFmx
export const getRevertVersion = (state: RootState) =>
  state.features.revertVersion
export const getShowPdfVersion = (state: RootState) =>
  state.features.showPdfVersion
export const getFeatureImpacted = (state: RootState) =>
  state.features.featureImpacted
export const getFeaturePblLandmarks = (state: RootState) =>
  state.features.featurePblLandmarks
export const getHidePblClassification = (state: RootState) =>
  state.features.hidePblClassification
export const getFeatureCopyToClipboard = (state: RootState) =>
  state.features.featureCopyToClipboard
export const getFeatureShowSSO = (state: RootState) =>
  state.features.featureShowSSO
export const getFeatureShowIteroLogin = (state: RootState) =>
  state.features.featureShowIteroLogin
export const getFeatureCoachMarks = (state: RootState) =>
  state.features.featureCoachMarks
export const getShowThemeTestButtons = (state: RootState) =>
  state.features.showThemeTestButtons
export const getFeatureWhatsNew = (state: RootState) =>
  state.features.featureWhatsNew
export const getFeatureHsmInPeri = (state: RootState) =>
  state.features.featureHsmInPeri
export const getShowSetupInOnboarding = (state: RootState) =>
  state.features.showSetupInOnboarding
export const getFeatureShowTimeOut = (state: RootState) =>
  state.features.featureShowTimeOut
export const getFeatureCroppedTeeth = (state: RootState) =>
  state.features.featureCroppedTeeth
export const getFeatureShowNotes = (state: RootState) =>
  state.features.featureShowNotes
export const getFeaturePatientFileConfirmedAnnotations = (state: RootState) =>
  state.features.featurePatientFileConfirmedAnnotations
export const getFeatureShowOrthoWarning = (state: RootState) =>
  state.features.featureShowOrthoWarning
export const getFeatureAlfaDocsIntegration = (state: RootState) =>
  state.features.featureAlfaDocsIntegration
export const getFeatureRememberHsm = (state: RootState) =>
  state.features.featureRememberHsm
export const getFeatureDynamicPbl = (state: RootState) =>
  state.features.featureDynamicPbl
export const getRegulatoryMarket = (state: RootState) =>
  state.features.regulatoryMarket
export const getManualVersion = (state: RootState) =>
  state.features.manualVersion
export const getManualLanguages = (state: RootState) =>
  state.features.manualLanguages
export const getMirthInstaller = (state: RootState) =>
  state.features.mirthInstaller
export const getFeatureUploadFromPatientFile = (state: RootState) =>
  state.features.featureUploadFromPatientFile
