import { createAction } from "typesafe-actions"

import { XraysTypes } from "../types/xraysTypes"
import { CreateRadiographSet, UpdateSet } from "../types/patientTypes"

export const deleteXrayStart = createAction(
  XraysTypes.DELETE_XRAY_START
)<string>()

export const deleteMultipleRadiographs = createAction(
  XraysTypes.DELETE_MULTIPLE_RADIOGRAPHS
)<string[]>()

export const requestDeleteRadiograph = createAction(
  XraysTypes.REQUEST_DELETE_RADIOGRAPH
)<string>()

export const createRadiographSet = createAction(
  XraysTypes.CREATE_RADIOGRAPH_SET
)<CreateRadiographSet>()

export const updateRadiographSet = createAction(
  XraysTypes.UPDATE_RADIOGRAPH_SET
)<UpdateSet>()

export const removeRadiographSet = createAction(
  XraysTypes.REMOVE_RADIOGRAPH_SET
)<string>()
