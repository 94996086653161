import { createAction } from "typesafe-actions"

import {
  imageTypes,
  PdfReport,
  PdfReportData,
  RadiographType,
  RotateData,
  SendReport,
} from "library/common/types/imageTypes"
import { ResultStatus } from "../types/dataStructureTypes"

export const loadImage = createAction(imageTypes.LOAD_IMAGE)<string>()
export const loadImageSuccess = createAction(
  imageTypes.LOAD_IMAGE_SUCCESS
)<string>()
export const imageProcessingComplete = createAction(
  imageTypes.IMAGE_PROCESSING_COMPLETE
)<string>()
export const loadAnnotations = createAction(
  imageTypes.LOAD_ANNOTATIONS
)<string>()
export const loadAnnotationsSuccess = createAction(
  imageTypes.LOAD_ANNOTATIONS_SUCCESS
)()
export const setShownRadiographAnnotations = createAction(
  imageTypes.SET_SHOWN_RADIOGRAPH_ANNOTATIONS
)<string>()
export const setInitialState = createAction(imageTypes.SET_INITIAL_STATE)()
export const rotateImage = createAction(imageTypes.ROTATE_IMAGE)<RotateData>()
export const setAsNotProcessed = createAction(imageTypes.SET_AS_NOTPROCESSED)()
export const changeRadiographType = createAction(
  imageTypes.CHANGE_RADIOGRAPH_TYPE
)<RadiographType>()
export const updateOpenDateMs = createAction(imageTypes.UPDATE_OPENDATEMS)()
export const loadPdfReport = createAction(
  imageTypes.LOAD_PDF_REPORT
)<PdfReport>()
export const loadPdfReportSuccess = createAction(
  imageTypes.LOAD_PDF_REPORT_SUCCESS
)<PdfReportData>()
export const openPdfReport = createAction(imageTypes.OPEN_PDF_REPORT)()
export const setShowImmediately = createAction(
  imageTypes.SET_SHOW_IMMEDIATELY
)<boolean>()
export const reanalyzeImage = createAction(imageTypes.REANALYZE_IMAGE)()
export const revertImage = createAction(imageTypes.REVERT_IMAGE)<number>()
export const setAcceptMissingMetadata = createAction(
  imageTypes.SET_ACCEPT_MISSING_METADATA
)<boolean | null>()
export const saveReport = createAction(imageTypes.SAVE_REPORT)()
export const copyReportAsImage = createAction(imageTypes.COPY_REPORT_AS_IMAGE)()
export const copyReportAsText = createAction(imageTypes.COPY_REPORT_AS_TEXT)()
export const sendReport = createAction(imageTypes.SEND_REPORT)<SendReport>()
export const setImageResultStatus = createAction(
  imageTypes.SET_IMAGE_RESULT_STATUS
)<ResultStatus>()
export const setIsReportSentToAlfaDocs = createAction(
  imageTypes.SET_IS_REPORT_SENT_TO_ALFA_DOCS
)<boolean>()
