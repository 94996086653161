import { all, select, put, call, takeEvery } from "redux-saga/effects"

import { XraysTypes } from "library/common/types/xraysTypes"
import * as xrayActions from "../actions/xrays"
import { getImageIDBreadcrumb } from "../selectors/breadcrumbs"
import {
  deleteActivePatientXray,
  setActivePatientResultStatus,
  setActiveRadiographSet,
  setIsEditSetActive,
} from "../actions/patient"
import { setImageIDBreadcrumb } from "../actions/breadcrumbs"
import {
  requestCreateRadiographSet,
  requestDeleteXRay,
  requestRemoveRadiographSet,
  requestUpdateRadiographSet,
} from "library/services/xraysApi"
import { refreshPatientFile } from "./imageSaga"
import { ActivePatientResult } from "../types/patientTypes"
import { getActivePatientResult } from "../selectors/patient"
import { ResultStatus } from "../types/dataStructureTypes"

interface IDeleteXray {
  payload: string
  type: string
}

function* requestDeleteRadiographSaga({
  payload: id,
}: ReturnType<typeof xrayActions.requestDeleteRadiograph>) {
  yield put(setActivePatientResultStatus(ResultStatus.loading))
  try {
    yield call(requestDeleteXRay, id)

    yield put(setActivePatientResultStatus(ResultStatus.none))
    yield put(deleteActivePatientXray(id))
    yield call(refreshPatientFile)
    const imageIdBreadcrumb: string = yield select(getImageIDBreadcrumb)
    if (imageIdBreadcrumb.split("/")[2] === id) {
      yield put(setImageIDBreadcrumb(""))
    }
  } catch (error) {
    console.error(error)
  }
}

function* deleteXray(id: IDeleteXray) {
  yield put(xrayActions.requestDeleteRadiograph(id.payload))
  const activePatientResult: ActivePatientResult = yield select(
    getActivePatientResult
  )

  // Exit set-editing mode if the last image in the set is deleted
  const isLastDeletedRadiographSetImage = activePatientResult.radiographSets
    .filter((r) => r.id === activePatientResult.activeRadiographSet?.id)
    .map((a) => a.radiographs.length === 1)[0]

  if (isLastDeletedRadiographSetImage) {
    yield put(setIsEditSetActive(false))
  }
}

function* deleteMultipleRadiographs({
  payload: ids,
}: ReturnType<typeof xrayActions.deleteMultipleRadiographs>) {
  yield all(ids.map((i) => put(xrayActions.requestDeleteRadiograph(i))))
}

function* createRadiographSetSaga({
  payload: set,
}: ReturnType<typeof xrayActions.createRadiographSet>) {
  try {
    const { data } = yield call(requestCreateRadiographSet, set)
    yield put(setActiveRadiographSet(data))
    yield call(refreshPatientFile)
  } catch (error) {
    console.error(error)
  }
}

function* updateRadiographSetSaga({
  payload,
}: ReturnType<typeof xrayActions.updateRadiographSet>) {
  try {
    yield call(requestUpdateRadiographSet, payload.id, { ...payload.set })
    yield call(refreshPatientFile)
  } catch (error) {
    console.error(error)
  }
}

function* removeRadiographSetSaga({
  payload: id,
}: ReturnType<typeof xrayActions.removeRadiographSet>) {
  try {
    yield call(requestRemoveRadiographSet, id)
    yield put(setActiveRadiographSet(null))
    yield call(refreshPatientFile)
  } catch (error) {
    console.error(error)
  }
}

export default function* watchXRays() {
  yield takeEvery(XraysTypes.DELETE_XRAY_START, deleteXray)
  yield takeEvery(
    XraysTypes.DELETE_MULTIPLE_RADIOGRAPHS,
    deleteMultipleRadiographs
  )
  yield takeEvery(
    XraysTypes.REQUEST_DELETE_RADIOGRAPH,
    requestDeleteRadiographSaga
  )
  yield takeEvery(XraysTypes.CREATE_RADIOGRAPH_SET, createRadiographSetSaga)
  yield takeEvery(XraysTypes.UPDATE_RADIOGRAPH_SET, updateRadiographSetSaga)
  yield takeEvery(XraysTypes.REMOVE_RADIOGRAPH_SET, removeRadiographSetSaga)
}
