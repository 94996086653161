export enum DrawingTypes {
  SET_DRAWING_ACTION = "@@DRAWING/SET_DRAWING_ACTION",
  TOGGLE_ACTIVE_DRAWING_MODE = "@@DRAWING/TOGGLE_ACTIVE_DRAWING_MODE",
  SET_DRAWING_ANNOTATION = "@@DRAWING/SET_DRAWING_ANNOTATION",
  SET_INITIAL_STATE = "@@DRAWING/SET_INITIAL_STATE",
  SET_DRAWING_LINE_THICKNESS = "@@DRAWING/SET_DRAWING_LINE_THICKNESS",
  SET_DRAWING = "@@DRAWING/SET_DRAWING",
  DELETE_DRAWING_CANVAS = "@@DRAWING/DELETE_DRAWING_CANVAS",
  SET_SHOW_DRAWING_WARNING = "@@DRAWING/SET_SHOW_DRAWING_WARNING",
  SET_ACTIVE_PERI_DRAWING = "@@DRAWING/SET_ACTIVE_PERI_DRAWING",
  SET_ACTIVE_DRAWING_MASK = "@@DRAWING/SET_ACTIVE_DRAWING_MASK",
  SAVE_DRAWING_ANNOTATION = "@@DRAWING/SAVE_DRAWING_ANNOTATION",
  SET_ACTIVE_ADDITION = "@@DRAWING/SET_ACTIVE_ADDITION",
  SET_IS_ERASING = "@@DRAWING/SET_IS_ERASING",
  SET_ACTIVE_CARIES_PRO_ID = "@@DRAWING/SET_ACTIVE_CARIES_PRO_ID",
  SET_ACTIVE_PERI_ID = "@@DRAWING/SET_ACTIVE_PERI_ID",
  SET_SHOW_DRAW_ANNOTATION_BUTTON = "@@DRAWING/SET_SHOW_DRAW_ANNOTATION_BUTTON",
}

export enum DrawingAction {
  select = "select",
  annotate = "annotate",
  caries = "caries",
  apical = "apical",
  calculus = "calculus",
  bridges = "bridges",
  crowns = "crowns",
  fillings = "fillings",
}

export enum DrawingLineThickness {
  thin = 3,
  medium = 6,
  thick = 9,
}

export enum DrawingAnnotation {
  none = "",
  caries = "caries",
  apical = "apical",
  calculus = "calculus",
  bridges = "bridges",
  crowns = "crowns",
  fillings = "fillings",
}
